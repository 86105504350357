import React from 'react'
import * as classes from './SectionTitle.module.scss'
import cn from 'classnames'

function SectionTitle({ title, className, light, lang }) {
    return (
        <p
            className={cn(classes.SectionTitle, className, { [classes.Light]: light }, { [classes.LangHY]: lang === 'hy' })}
            dangerouslySetInnerHTML={{ __html: title }}
        />
    )
}

export default SectionTitle
