// extracted by mini-css-extract-plugin
export var Intro = "Intro-module--Intro--6Aldk";
export var Bg = "Intro-module--Bg--MrEDR";
export var Relative = "Intro-module--Relative--jt7nz";
export var TitleWrap = "Intro-module--TitleWrap--Z7csq";
export var Title = "Intro-module--Title--yOSsr";
export var LinkWrap = "Intro-module--LinkWrap--52qt3";
export var IconLeftWrap = "Intro-module--IconLeftWrap--auLUU";
export var Arrow = "Intro-module--Arrow--50hKp";
export var LangHY = "Intro-module--LangHY--qA4Os";
export var arrowLeft = "Intro-module--arrowLeft--Ti93x";
export var arrowRight = "Intro-module--arrowRight--QQVgT";