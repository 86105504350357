import React from 'react'

function IconLeft({ className }) {
    return (
        <svg className={className} width='22' height='17' viewBox='0 0 22 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M8.48404 0.109707C8.4109 0.0365692 8.3012 0 8.15492 0C8.04521 0 7.9355 0.0365692 7.86237 0.109707L0.146276 7.86237C0.0731373 7.93551 0 8.04521 0 8.15492C0 8.3012 0.0731373 8.37434 0.146276 8.44747L7.86237 16.2001C7.9355 16.2733 8.04521 16.3098 8.15492 16.3098C8.3012 16.3098 8.4109 16.2733 8.48404 16.2001L8.74003 15.9076C8.81316 15.8344 8.84973 15.7613 8.84973 15.615C8.84973 15.5053 8.81316 15.3956 8.74003 15.2859L2.19415 8.7766H21.5612C21.7074 8.7766 21.8172 8.74003 21.8903 8.66689C21.9634 8.59375 22 8.48404 22 8.33777V7.97207C22 7.86237 21.9634 7.75266 21.8903 7.67952C21.8172 7.60638 21.7074 7.53325 21.5612 7.53325H2.19415L8.74003 1.02394C8.81316 0.950798 8.84973 0.841091 8.84973 0.694814C8.84973 0.585106 8.81316 0.475399 8.74003 0.402261L8.48404 0.109707Z'
                fill='white'
            />
        </svg>
    )
}

export default IconLeft
