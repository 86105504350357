// extracted by mini-css-extract-plugin
export var Section = "Management-module--Section--oCCKA";
export var Content = "Management-module--Content--EISQn";
export var Items = "Management-module--Items--8E4Jo";
export var ItemTitle = "Management-module--ItemTitle--eM7An";
export var ItemImg = "Management-module--ItemImg--hhJOn";
export var Experience = "Management-module--Experience--pfK+r";
export var ExperienceTitle = "Management-module--ExperienceTitle--Dt3+E";
export var CircleWrap = "Management-module--CircleWrap--8klnI";
export var DotWrap = "Management-module--DotWrap--GIKLa";
export var Active = "Management-module--Active---ZpzU";
export var Dot = "Management-module--Dot--PkHZE";
export var CircleTextWrap = "Management-module--CircleTextWrap--X0ZPN";
export var CircleText = "Management-module--CircleText--URP7R";
export var Item = "Management-module--Item--TN3Jl";
export var arrowLeft = "Management-module--arrowLeft--6bdvc";
export var arrowRight = "Management-module--arrowRight--RjwVu";