import React, { useEffect, useState, useRef, useMemo, useContext } from 'react'
import * as classes from './Management.module.scss'
import Container from '../../../ui/Container/Container'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import cn from 'classnames'
import anime from 'animejs'
import { deleteHtmlTag } from '../../../../utils/deleteHtmlTag'
import { FormContext } from '../../../../context'
import splitRow from '../../../../utils/splitRow'
import { startAnimationScroll } from '../../../../utils/animations'
import splitTitleV2 from '../../../../utils/splitTitleV2'

function Management({ data, lang }) {
    const { animationStart } = useContext(FormContext)
    const [activeSlide, setActiveSlide] = useState(0)

    const textsRef = useRef([])
    const countRef = useRef(0)
    const currentSlideRef = useRef(null)
    const activeDotRef = useRef(null)

    const sectionTitle = useMemo(() => deleteHtmlTag(data.titleSmall.value, 'p'), [])
    const titleFormat = useMemo(() => deleteHtmlTag(data.title.value, 'p'), [])
    const text = useMemo(() => deleteHtmlTag(data.text.value, 'p'), [])
    const experienceTitle = useMemo(() => deleteHtmlTag(data.experienceTitle.value, 'p'), [])

    const content = useMemo(
        () => [
            {
                name: deleteHtmlTag(data.blockLeft.value, 'p'),
                photo: data.blockLeft.photo.url
            },
            {
                name: deleteHtmlTag(data.blockRight.value, 'p'),
                photo: data.blockRight.photo.url
            }
        ],
        []
    )

    const slides = useMemo(
        () =>
            data.slides.nodes.map((item, index) => {
                return { title: deleteHtmlTag(item.value, 'p'), id: index }
            }),
        []
    )

    const onChangeActiveSlide = () => {
        const newIndex = activeSlide === slides.length - 1 ? 0 : activeSlide + 1

        anime({
            targets: activeDotRef.current,
            duration: 1400,
            easing: 'cubicBezier(.66, 0, .3, .99)',
            rotate: [`${countRef.current}deg`, `${countRef.current + 60}deg`]
        })

        anime({
            targets: textsRef.current[activeSlide],
            duration: 1400,
            opacity: [1, 0],
            easing: 'easeInOutQuad',
            top: [`${0}%`, `${-100}%`]
        })

        anime({
            targets: textsRef.current[newIndex],
            duration: 1400,
            opacity: [0, 1],
            easing: 'easeInOutQuad',
            top: [`${100}%`, `${0}%`]
        })

        countRef.current += 60
        setActiveSlide(newIndex)
    }

    const [title, setTitle] = useState({
        top: titleFormat,
        people: content && content.length ? content.map((item) => item.name) : [],
        experience: experienceTitle
    })

    const classAnimationTitle = 'anime-title-shareholders'
    const classAnimationText = 'anime-text-shareholders'

    useEffect(() => {
        if (animationStart) {
            splitRow(classAnimationText)
            splitTitleV2(classAnimationTitle)
        }

        const observer = startAnimationScroll([
            { type: 'title', className: classAnimationTitle },
            { type: 'text', className: classAnimationText }
        ])

        return () => {
            if (observer) {
                observer.disconnect()
            }
        }
    }, [animationStart])

    useEffect(() => {
        const intervalId = setInterval(() => {
            onChangeActiveSlide()
        }, 3000)

        return () => clearInterval(intervalId)
    }, [activeSlide])

    useEffect(() => {
        textsRef.current = textsRef.current.slice(0, slides.length)
        textsRef.current[activeSlide].style.top = 0
    }, [])

    return (
        <section className={classes.Section}>
            {sectionTitle || title.top || text || (content && content.length) ? (
                <Container>
                    {sectionTitle ? <SectionTitle title={sectionTitle} lang={lang} /> : null}

                    {title || text ? (
                        <div className={classes.Content}>
                            {title.top ? <h2 className={classAnimationText} dangerouslySetInnerHTML={{ __html: title.top }} /> : null}

                            {text ? (
                                <div className={classAnimationText}>
                                    <p dangerouslySetInnerHTML={{ __html: text }} />
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    {content && content.length ? (
                        <div className={classes.Items}>
                            {content.map((item, index) => (
                                <div className={classes.Item} key={index}>
                                    {item.name ? (
                                        <p
                                            className={`${classes.ItemTitle} ${classAnimationTitle}`}
                                            dangerouslySetInnerHTML={{ __html: title.people[index] }}
                                        />
                                    ) : null}

                                    {item.photo ? (
                                        <div className={classes.ItemImg} style={{ backgroundImage: `url(${item.photo})` }} />
                                    ) : null}
                                </div>
                            ))}
                        </div>
                    ) : null}
                </Container>
            ) : null}

            {title.experience || (slides && slides.length) ? (
                <div className={cn(classes.Experience, 'js-block-dark')}>
                    {title.experience ? (
                        <p
                            className={`${classes.ExperienceTitle} ${classAnimationTitle}`}
                            dangerouslySetInnerHTML={{ __html: title.experience }}
                        />
                    ) : null}

                    {slides && slides.length ? (
                        <div className={classes.CircleWrap}>
                            <div className={`${classes.DotWrap} ${classes.Active}`} ref={activeDotRef}>
                                <span className={classes.Dot} />
                            </div>

                            {slides.map((item) => (
                                <div className={cn(classes.DotWrap)} key={item.id} data-id={item.id}>
                                    <span className={classes.Dot} />
                                </div>
                            ))}

                            <div className={classes.CircleTextWrap} ref={currentSlideRef}>
                                {slides.map((item, index) => (
                                    <p
                                        className={classes.CircleText}
                                        key={item.id}
                                        ref={(el) => (textsRef.current[index] = el)}
                                        dangerouslySetInnerHTML={{ __html: item.title }}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </section>
    )
}

export default Management
